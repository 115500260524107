import React, { Component } from 'react';
import { Link } from "gatsby"
  import '../components/blog.css';
  import { Row,Col, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
  import {Helmet} from "react-helmet";
  import HU from '../components/hu-HU_lang.js';
  import EN from '../components/en-EN_lang.js'; 
  import Layout from "../components/layout";
  import config from '../components/strapiConfig.js';
class BlogPage extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true,
      articles: [],
      searchValue: ""
    }
    this.getArticles().then(data=>{
        this.setState({ articles: data})
        console.log(data)
    })
    this.searchTest = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
  onChange = (e) =>{
    e.preventDefault();    
    this.setState({
      searchValue: this.searchTest.current.value
    })
  }
  searchPost = (e) =>{
    e.preventDefault();
    this.setState({articles: []});
    this.getArticleByName(this.state.searchValue).then(filtered =>{
      this.setState({articles: filtered})
    })
  }
  getArticleByName = async (data) =>{
    const token = "Bearer "+ process.env.REACT_APP_API_KEY
    //console.log(token);
    //const token = config.token
    const response = await fetch(`https://blingdy.app/articles?UID_contains=${data}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authorization": token
          },
      });
      const body = await response.json();
      return body;
  }
  getArticles = async () => {
    const token = "Bearer "+ process.env.REACT_APP_API_KEY
    //const token = config.token
    //console.log(token);
    const response = await fetch("https://blingdy.app/articles", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "authorization": token
          },
      });
      const body = await response.json();
      return body;
}

render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  console.log(process.env.REACT_APP_API_KEY)
  var userLang = getRedirectLanguage();
  console.log(userLang)
    var fetchedArticles = this.state.articles
    var allArticle = fetchedArticles.map(data =>{
      return <Link to={`/blog/${data.UID}`} key={data.UID}>
          <div className="blogBox">
              <Row>
                  <Col className="blogBoxLeft" sm={4} md={4} xl={4}>
                      <img src={"https://blingdy.app"+data.coverImage.url} />
                  </Col>
                  <Col className="blogBoxRight" sm={8} md={8} xl={8}>
                      <h2>{data.Title}</h2>
                      <h3>{data.content.substring(0, 169) + "..."}</h3>
                      <p>{data.created_at.substr(0, 10)}</p>
                  </Col>
              </Row>
          </div>
      </Link>
      
  })
    return(
      <Layout>
      <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
      <Helmet>
          <title>Automeasify Blog - IT Automatizálás</title>
          <meta name="description" content="Üdvözöljük a blogunkban! Olvassa el a legújabb cikkeket olyan témákról, mint az automatizálás, az IT-üzemeltetés, a szolgáltatásmenedzsment, az IT-infrastruktúra és még sok más..." />
          <meta property="og:title" content="Automeasify Blog - IT Automatizálás" />
          <meta property="og:type" content="website" />
          <meta property="og:description " content="Üdvözöljük a blogunkban! Olvassa el a legújabb cikkeket olyan témákról, mint az automatizálás, az IT-üzemeltetés, a szolgáltatásmenedzsment, az IT-infrastruktúra és még sok más..." />
          <meta property="og:url" content="https://automeasify.com/blog" />
          <meta property="og:image" content="https://blingdy.app/uploads/Email_Automeasify_d98936b890.PNG" />
      </Helmet>
        <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogHeader : EN.blogHeader}</h2>
      </div>
      <div className="contentDivFylerBlogMain">
          <Row>
              <Col xl={3} md={3} sm={12}>
              <InputGroup className="mb-3">
                    <FormControl
                    onChange={this.onChange}
                    ref={this.searchTest}
                    placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogSearch : EN.blogSearch}
                    aria-label={userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogSearch : EN.blogSearch}
                    aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                    <Button variant="outline-primary" onClick={this.searchPost}>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogButton : EN.blogButton}</Button>
                    </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col xl={9} md={9} sm={12}>
                {allArticle}
              </Col>
          </Row>
        
      </div>
  </div>
  </Layout>
    );
  }
}
export default BlogPage