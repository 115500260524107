import React, { Component } from 'react';
import { Link } from "gatsby"
  import './homepage.css';
  import { Row, Col, Form } from 'react-bootstrap';
  import Button1 from './button1.js';
  import Button2 from './button2.js';
  import Button3 from './button3.js';
  import Button4 from './button4.js';
  import logo1 from '../images/azurelogo.png'
import coverimg from '../images/cover1.png';
import dashMainScreen from '../images/dashmain1.png';
import howItWorks from '../images/howItworks.png';
import smallIcon1 from '../images/atmsfy-web-01.png';
import pic2 from '../images/pic2.png';
import pic3 from '../images/picc2.png';
import pic11 from '../images/pic11.png';
import pic13 from '../images/pic13.png';
import pic4 from '../images/pic3.png';
import mfsm from '../images/mfsm.svg';
import o365 from '../images/o365.png';
import winad from '../images/winad.png';
import mssql from '../images/mssql.png';
import winserver from '../images/winserver.png';
import jira from '../images/jira.png';
import win10 from '../images/win10.png';
import mscert from '../images/mscert.svg';
import powershell from '../images/powershell.png';
import exchange from '../images/exchange.png';
import hyperv from '../images/hyperv.png';
import topdesk from '../images/topdesk.png';
import freshservice from '../images/freservice.png';
import zendesk from '../images/zendesk.png';
import servicenow from '../images/servicenow.png';
import aws from '../images/aws.png';
import freshdesk from '../images/freshdesk.svg';
import PDF from './atmsfy-privacy-policy.pdf';
import {Helmet} from "react-helmet";
import HU from './hu-HU_lang.js';
import EN from './en-EN_lang.js';
import Layout from "../components/layout"

class Homepage extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      firstName: "",
      lastName: "",
      email: ""
    }
    this.text1 = React.createRef();
    this.text2 = React.createRef();
    this.text3 = React.createRef();
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  onChange = (e) =>{
    e.preventDefault();    
    this.setState({
      firstName: this.text1.current.value,
      lastName: this.text2.current.value,
      email: this.text3.current.value
    })
  }
  submitForm = async (e) => {
    e.preventDefault();
    const token = "Bearer "+ process.env.REACT_APP_API_KEY
    console.log(token);
    const response = await fetch("https://blingdy.app/hello", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "authorization": token
          },
        body: JSON.stringify({ 
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddr: this.state.email
        }),
      });
      const body = await response.json();
      console.log(body);
      window.location.reload(true);
}

render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
      <Layout>
        <div className="mainPageDiv">
          <Helmet>
          <title>Automeasify - Az #1 IT Üzemeltetés Szolgáltatás Automatizálás szoftver!</title>
          <meta name="description" content="Automatizálja az IT infrastruktúra működését a ügyfél kéréstől a tényleges operatív munkáig. Azokra a informatikai folyamatokra összpontosítunk, amelyek az ügyféltől indulnak, körbejárják az IT üzemeltetést, és egy teljesen automatizált sikeres megoldással térnek vissza." />
          <meta property="og:title" content="Automeasify - Az #1 IT Üzemeltetés Szolgáltatás Automatizálás szoftver!" />
          <meta property="og:type" content="website" />
          <meta property="og:description " content="Automatizálja az IT infrastruktúra működését a ügyfél kéréstől a tényleges operatív munkáig. Azokra a informatikai folyamatokra összpontosítunk, amelyek az ügyféltől indulnak, körbejárják az IT üzemeltetést, és egy teljesen automatizált sikeres megoldással térnek vissza." />
          <meta property="og:url" content="https://automeasify.com/" />
          <meta property="og:image" content="https://blingdy.app/uploads/banner828_7146d1f5a9.png" />
        </Helmet>
            <Row className="coverImgDiv">
            <img src={coverimg} />
            </Row>
            <Row>              
              <Col>
                <div className="home1RowLeft">
                  <h4>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.MAIN1 : EN.MAIN1}</h4>
                  <h1>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.MAIN2 : EN.MAIN2}</h1>
                  <h2>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.MAIN3 : EN.MAIN3}</h2>
                  <Link to="/kalkulator"><Button1 /></Link>
                  
                </div>
              </Col>
              <Col>
                <div className="home1RowRight">
                  <img alt="automeasifyMainDashboard" src={dashMainScreen} />
                </div>
              </Col>
            </Row>


            <Row>
              <div className="home2Row">
                <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeHeader1 : EN.homeHeader1}</h4>
                <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeContent1 : EN.homeContent1}</h3>
                <img alt="howAutomeasifyWorks" src={howItWorks} />
              </div>
            </Row>


            <Row className="home3Row">
              <Col sm={12}xl={6} md={6}>
                <div className="home3RowLeft">
                    <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeHeader2 : EN.homeHeader2}</h4>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeContent2 : EN.homeContent2}</h3>
                    <Button2 />
                </div>
              </Col>
              <Col sm={12}xl={6}md={6}>
                <div className="home3RowRight">
                    <Row>
                      <Col sm={12} xl={6}md={6}>
                        <div className="flyerBox1">
                          <img src={pic3} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox1Header : EN.homeBox1Header}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox1 : EN.homeBox1}</p>
                        </div>
                      </Col>
                      <Col sm={12}xl={6}md={6}>
                        <div className="flyerBox1">
                          <img src={pic11} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox2Header : EN.homeBox2Header}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox2 : EN.homeBox2}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}xl={6}md={6}>
                        <div className="flyerBox1">
                          <img src={pic13} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox3Header : EN.homeBox3Header}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox3 : EN.homeBox3}</p>
                        </div>
                      </Col>
                      <Col sm={12}xl={6}md={6}>
                        <div className="flyerBox1">
                          <img src={pic4} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox4Header : EN.homeBox4Header}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBox4 : EN.homeBox4}</p>
                        </div>
                      </Col>
                    </Row>
                </div>
              </Col>
            </Row>


            <Row>
              <div className="home4Row">
                <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeHeader3 : EN.homeHeader3}</h3>
                <Row>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationCommingSoon">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBoxes3 : EN.homeBoxes3}</h6>
                      <img altr="integrationAzureLogo" src={servicenow} />
                      <h4>ServiceNOW</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationCommingSoon">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBoxes3 : EN.homeBoxes3}</h6>
                      <img altr="integrationAzureLogo" src={freshservice} />
                      <h4>Freshservice</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationCommingSoon">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBoxes3 : EN.homeBoxes3}</h6>
                      <img altr="integrationAzureLogo" src={aws} />
                      <h4>Amazon Web Services</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationCommingSoon">
                      <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeBoxes3 : EN.homeBoxes3}</h6>
                      <img altr="integrationAzureLogo" src={topdesk} />
                      <h4>TOPDesk</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={mfsm} />
                      <h4>Micro Focus Service Manager</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={jira} />
                      <h4>JIRA Service Desk</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={winad} />
                      <h4>Microsoft Active Directory</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={win10} />
                      <h4>Microsoft Windows 10</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={powershell} />
                      <h4>Microsoft Powershell</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={exchange} />
                      <h4>Microsoft Exchange</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={hyperv} />
                      <h4>Microsoft Hyper-V</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={mssql} />
                      <h4>Microsoft SQL</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={mscert} />
                      <h4>Microsoft Certificate Authority</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={o365} />
                      <h4>Microsoft Office 365</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={winserver} />
                      <h4>Windows Servers</h4>
                    </div>
                  </Col>
                  <Col xs={6} xl={3} md={3}>
                    <div className="integrationReqgular">
                      <img altr="integrationAzureLogo" src={logo1} />
                      <h4>Microsoft Azure</h4>
                    </div>
                  </Col>
                </Row>
                <a href="/integrations">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeContent3 : EN.homeContent3}</a>
              </div>
            </Row>


            <Row className="home5Row">
              <Col xs={12} xl={6} md={6}>
                <div className="home5RowLeft">
                  <img alt="automeasifyAppPictures" src={pic2} />
                </div>
              </Col>
              <Col xs={12} xl={6} md={6}>
                <div className="home5RowRight">
                  <h3>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeHeader4 : EN.homeHeader4}</h3>
                  <h2>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.homeContent4 : EN.homeContent4}</h2>
                  <Button2 />
                </div>
              </Col>
            </Row>


            <Row className="home6Row">
              <Col xs={12} xl={6}  md={6}>
                <div className="home6RowLeft">
                  <h3>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactHeader : EN.contactHeader}</h3>
                  <p>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactContent1 : EN.contactContent1}</p>
                  <h4>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactContent2 : EN.contactContent2}</h4>
                  <Link to="/pricing"><Button3 /></Link>
                </div>
              </Col>
              <Col xs={12} xl={6} md={6}>
                <div className="home6RowRight">
                  <div className="flyerBoxContact">
                    <h3>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxHeader : EN.contactBoxHeader}</h3>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput1 : EN.contactBoxInput1} value={this.state.firstName} 
               ref={this.text1} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput2 : EN.contactBoxInput2} value={this.state.lastName} 
               ref={this.text2} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder={ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput3 : EN.contactBoxInput3} value={this.state.email} 
               ref={this.text3} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" required/>
                        <Form.Check.Label>
                        { userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText1 : EN.contactBoxText1}<a href={PDF} target="_blank"><b>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText2 : EN.contactBoxText2}</b></a> { userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText4 : EN.contactBoxText4} <a href={PDF} target="_blank"><b>{ userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText3 : EN.contactBoxText3}</b></a>            
                        </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      <Button4 />
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>

        
        </div>
        </Layout>
    );
  }
}
export default Homepage