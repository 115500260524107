import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPage from '../components/mainRouter.js';
import '../components/mainstyle.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-179645110-1');
if (typeof window !== 'undefined'){
  ReactGA.pageview(window.location.pathname + window.location.search);
}
class Mainpage extends Component{
  constructor(props){
    super(props)
    this.state={
      mobile: false
    }
  }
  render(){
    return(
    <MainPage />
    );
  }
}

export default Mainpage