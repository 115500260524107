import React, { Component } from 'react';
  import './buttons.css';
  import HU from './hu-HU_lang.js';
  import EN from './en-EN_lang.js'; 

class Button3 extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[]
    }
  }
 
render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
        <div className="button3">
            <button>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.button1 : EN.button1}</button>
        </div>
    );
  }
}
export default Button3