import React, { Component } from 'react';
import { Link } from "gatsby"
  import './blog_unique.css';
  import { Row,Col, Form } from 'react-bootstrap';
  import Button4 from './button4.js';
  import PDF from './atmsfy-privacy-policy.pdf';
  import {Helmet} from "react-helmet";
  import HU from './hu-HU_lang.js';
  import EN from './en-EN_lang.js'; 
  import Layout from "./layout"
  const ReactMarkdown = require('react-markdown')

class BlogPostPage extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true,
      postData: [],
      dateOfCreation: Date()
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
  onChange = (e) =>{
    e.preventDefault();    
    this.setState({
      firstName: this.text1.current.value,
      lastName: this.text2.current.value,
      email: this.text3.current.value
    })
  }

render(){
  console.log(this.props)
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
      <Layout>
      <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
        <Helmet>
          <title>{this.props.pathContext.postData.Title}</title>
          <meta name="description" content={this.props.pathContext.description} />
          <meta property="og:title" content={this.props.pathContext.postData.Title} />
          <meta property="og:type" content="website" />
          <meta property="og:description " content={this.props.pathContext.description} />
          <meta property="og:url" content={"https://automeasify.com/post/"+this.props.pathContext.id} />
          <meta property="og:image" content={"https://blingdy.app"+this.props.pathContext.imgUrl} />
      </Helmet>
      </div>
      <div className="contentDivFylerBlog">
        <img src={"https://blingdy.app"+this.props.pathContext.imgUrl}></img>
        <Row className="headRowBlogPost">
            <Col xl={10} md={10} sm={8}>
                <h6>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.blogAuthor : EN.blogAuthor}: Stefel Viktor</h6>
            </Col>
            <Col xl={2} md={2} sm={4}>
                <p>{this.props.pathContext.create_date}</p>
            </Col>
        </Row>
        <h2>{this.props.pathContext.postData.Title}</h2>
        <div className="contentDivBlog">
        <ReactMarkdown source={this.props.pathContext.postData.content} />
        </div>
        <button className="backToAllBlog"><Link to="/blog">{userLang === "hu-HU" || userLang === "hu-hu" ? HU.button5 : EN.button5}</Link></button>
        <div className="blogPageContact">
          <div className="home6RowRight2">
                  <div className="flyerBoxContact2">
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxHeader : EN.contactBoxHeader}</h3>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput1 : EN.contactBoxInput1} value={this.state.firstName} 
               ref={this.text1} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput2 : EN.contactBoxInput2} value={this.state.lastName} 
               ref={this.text2} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput3 : EN.contactBoxInput3} value={this.state.email} 
               ref={this.text3} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" required/>
                        <Form.Check.Label>
                        {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText1 : EN.contactBoxText1} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText2 : EN.contactBoxText2}</b></a> {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText4 : EN.contactBoxText4} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText3 : EN.contactBoxText3}</b></a> 
                        </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      <Button4 />
                    </Form>
                  </div>
                </div>
        </div>
        
      </div>
  </div>
  </Layout>
    );
  }
}
export default BlogPostPage