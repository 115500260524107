import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from '../components/homepage.js';
import '../components/mainstyle.css';
import { Router } from "@reach/router";
import Integrations from '../pages/integraciok.js';
import BlogPage from '../pages/blog.js';
import BlogPageUnique from './post.js';
import Functions from '../pages/funkciok.js';
import Contact from '../pages/kontakt.js';
import Calculator from '../pages/kalkulator.js';
import NotFound from '../pages/404.js';

class Mainpage extends Component{
  constructor(props){
    super(props)
    this.state={
      mobile: true
    }
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
    const device = window.innerWidth
    //alert(device)
    if(device < 600){
      //alert(true)
      this.setState({
        mobile: true
      })
    }
  }
  render(){
    console.log(this.state)
    return(
        <div className="">
            <Router>
                <HomePage path="/" />
                <Integrations path="integraciok" />
                <Functions path="funkciok" />
                <Calculator path="kalkulator" />
                <Contact path="kontakt" />
                <BlogPage path="blog" />
                <NotFound default/>
            </Router>
        </div>        
    );
  }
}

export default Mainpage