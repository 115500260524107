import React, { Component } from 'react';
import { Link } from "gatsby"
  import '../components/contact.css';
  import { Row,Col, Form } from 'react-bootstrap';
  import Button3 from '../components/button3.js';
  import Button4 from '../components/button4.js';
  import PDF from '../components/atmsfy-privacy-policy.pdf';
  import {Helmet} from "react-helmet";
  import HU from '../components/hu-HU_lang.js';
  import EN from '../components/en-EN_lang.js';
  import Layout from "../components/layout";
class Contact extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true,
      finished: false
    }
    this.text1 = React.createRef();
    this.text2 = React.createRef();
    this.text3 = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
  onChange = (e) =>{
    e.preventDefault();    
    this.setState({
      firstName: this.text1.current.value,
      lastName: this.text2.current.value,
      email: this.text3.current.value
    })
  }
  submitForm = async (e) => {
    e.preventDefault();
    const token = "Bearer "+ process.env.REACT_APP_API_KEY
    //console.log(token);
    const response = await fetch("https://blingdy.app/hello", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "authorization": token
          },
        body: JSON.stringify({ 
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          emailAddr: this.state.email
        }),
      });
      const body = await response.json();
      console.log(body);
      this.setState({finished: true});
      window.location.reload(true);
}

render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
        <Layout>
      <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
      <Helmet>
          <title>Keressen fel minket! Azért vagyunk, hogy segítsünk önnek!</title>
          <meta name="description" content="Létrehoztuk az Automeasify # 1 IT Üzemeltetés Szolgáltatás Automatizáló eszközt, hogy megoldja a valós problémákat, amelyek időt takaríthatnak meg és költségeket takaríthatnak meg az IT-műveletek számára. Azért vagyunk itt, hogy segítsünk Önnek, és alig várjuk, hogy a közeljövőben közös sikereket érjünk el az Automeasify-vel." />
          <meta property="og:title" content="Keressen fel minket! Azért vagyunk, hogy segítsünk önnek az IT Automatizálásban!" />
          <meta property="og:type" content="website" />
          <meta property="og:description " content="Létrehoztuk az Automeasify # 1 IT Üzemeltetés Szolgáltatás Automatizáló eszközt, hogy megoldja a valós problémákat, amelyek időt takaríthatnak meg és költségeket takaríthatnak meg az IT-műveletek számára. Azért vagyunk itt, hogy segítsünk Önnek, és alig várjuk, hogy a közeljövőben közös sikereket érjünk el az Automeasify-vel." />
          <meta property="og:url" content="https://automeasify.com/kontakt" />
          <meta property="og:image" content="https://blingdy.app/uploads/banner828_7146d1f5a9.png" />
        </Helmet>
        <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.footerMenu8 : EN.footerMenu8}</h2>
      </div>
      <div className="contentDiv">
        {this.state.finished ? <Row className="contentDivFyler4"><h2>Thank you for contacting us! We will be connect with you soon.</h2><br></br><br></br><br></br><br></br><br></br><br></br><br></br></Row> : <Row className="contentDivFyler4">
        <Row className="home6Row2">
              <Col sm={12} md={6} xl={6}>
                <div className="home6RowLeft2">
                  <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactHeader2 : EN.contactHeader2}</h3>
                  <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactContent1 : EN.contactContent1}</p>
                  <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactContent2 : EN.contactContent2}</h4>
                  <Link to="/pricing"><Button3 /></Link>
                </div>
              </Col>
              <Col sm={12} md={6} xl={6}>
                <div className="home6RowRight2">
                  <div className="flyerBoxContact2">
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxHeader : EN.contactBoxHeader}</h3>
                    <Form onSubmit={this.submitForm}>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput1 : EN.contactBoxInput1} value={this.state.firstName} 
               ref={this.text1} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicText">
                        <Form.Control type="text" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput2 : EN.contactBoxInput2} value={this.state.lastName} 
               ref={this.text2} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" placeholder={userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxInput3 : EN.contactBoxInput3} value={this.state.email} 
               ref={this.text3} onChange={this.onChange} required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox">
                        <Form.Check.Input type="checkbox" required/>
                        <Form.Check.Label>
                        {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText1 : EN.contactBoxText1} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText2 : EN.contactBoxText2}</b></a> {userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText4 : EN.contactBoxText4} <a href={PDF} target="_blank"><b>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.contactBoxText3 : EN.contactBoxText3}</b></a> 
                        </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      <Button4 />
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
        </Row>
      }
      </div>
  </div>
  </Layout>
    );
  }
}
export default Contact